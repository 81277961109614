<template>
  <div class="w-full h-full flex justify-between items-center flex-col p-4">
    
    <div class="text-zinc-400 flex justify-center items-center" >
      <Icon name="lets-icons:done-duotone" class="tiertickicon w-[2.25rem] h-[2.25rem] -mb-0.5 mr-1" :style="`color:${tickColor};`"></Icon>
      <p>Tier One</p>
    </div>

    <div class="flex justify-center items-center relative">

      <div class="bg-white/10 pr-7 pl-4 py-4 rounded-full flex justify-center items-center space-x-2">
        <div class="iconparent flex justify-center items-center relative w-[2.25rem] h-[2.25rem]">
          <Icon class="text-3xl text-turquoise-500 absolute lockicon opacity-100" name="uim:lock"></Icon>
          <Icon class="text-3xl text-turquoise-500 absolute unlockicon opacity-0" name="uim:unlock"></Icon>
          <Icon class="text-3xl text-turquoise-500 absolute tickicon opacity-0" name="mdi:tick-circle"></Icon>
        </div>
        <p v-if="!granted" class="text-xl text-white font-bold font-brand" >Private Project</p>
        <p v-if="granted" class="text-xl text-white font-bold font-brand" >Access Granted</p>

      </div>

      <Icon ref="privatecursor" class="privatecursor text-white absolute z-20 -bottom-4 -right-4 text-4xl" name="ph:cursor-fill"></Icon>
      
    </div>

    <div>
      <h2 class="text-xl font-bold font-brand text-white mb-2">Private Sale</h2>
      <p class="text-zinc-400 text-sm" >Access exclusivity. Early stage venture quality deals are made available to the $OXI community.</p>
    </div>

  </div>
</template>

<script setup>

  const { $anime } = useNuxtApp()

  
  const privatecursor = ref(null)

  const tickColor = ref('#ffffff')


  const granted = ref(false)

  const animate = () => {

    
    let tl = $anime.timeline()

    // move the cursor to icon
    // get the difference between the two elements

    let iconEl = document.querySelector('.tiertickicon')
    let cursorEl = document.querySelector('.privatecursor')

    let diffX = iconEl.getBoundingClientRect().x - cursorEl.getBoundingClientRect().x
    let diffY = iconEl.getBoundingClientRect().y - cursorEl.getBoundingClientRect().y

    let flicked = false

    tl.add({
      targets: '.privatecursor',
      translateX: `${diffX + 20}px`,
      translateY: `${diffY+10}px`,
      duration: 1000,
      easing:'easeInOutExpo'
    })

    tl.add({
      targets: '.privatecursor',
      scale: [
        { value: 0.6},
        { value: 1}
      ],
      duration: 500,
      easing:'easeInOutExpo',
      complete: () => {
        tickColor.value = '#27E9AF'

        $anime({
          targets: '.lockicon',
          opacity: 0,
          duration: 200,
        })

        $anime({
          targets: '.unlockicon',
          opacity: 1,
          duration: 200,
        })

      }
    })

    tl.add({
      targets: '.privatecursor',
      translateX: `${diffX-15}px`,
      translateY: `${diffY+85}px`,
      duration: 2000,
      easing:'easeInOutExpo'
    })

    tl.add({
      targets: '.iconparent',
      duration: 2000,
      rotate: '360deg',
      easing:'easeInOutExpo',
      update: (anim) => {
        // updates++;
        // progressLogEl.value = 'progress : '+Math.round(anim.progress)+'%';
        // updateLogEl.value = 'updates : '+updates;
        if(anim.progress >=50 && !flicked){
          // lockIconName.value = 'mdi:tick-circle'
          $anime({
            targets: '.unlockicon',
            opacity: 0,
            duration: 200,
          })

          $anime({
            targets: '.tickicon',
            opacity: 1,
            duration: 200,
          })

          flicked = true
        }
      }
    },'-=2000')

    tl.add({
      targets: '.privatecursor',
      translateX: `0px`,
      translateY: `${diffY+85}px`,
      duration: 1000,
      easing:'easeInOutExpo',
      update: (anim) => {
        // updates++;
        // progressLogEl.value = 'progress : '+Math.round(anim.progress)+'%';
        // updateLogEl.value = 'updates : '+updates;
        if(anim.progress >=50 && !granted.value){
          granted.value = true
        }
      }
    })

    tl.play()

    tl.finished.then(() => {
      
      // console.log('animation finished')

      setTimeout( () => {

        tickColor.value = '#ffffff'
        granted.value = false

        $anime({
            targets: '.iconparent',
            rotate: '0deg',
            duration: 0,
          })

        $anime({
            targets: '.lockicon',
            opacity: 1,
            duration: 0,
          })

        $anime({
            targets: '.unlockicon',
            opacity: 0,
            duration: 0,
          })

          $anime({
            targets: '.tickicon',
            opacity: 0,
            duration: 0,
          })

          $anime({
            targets: '.privatecursor',
            translateX: `0px`,
            translateY: `0px`,
            duration: 0,
          })

          animate()

      }, 1000)
    })

  }

  onMounted( () => {

    animate()

  })


</script>

<style>

</style>