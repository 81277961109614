<template>
  <div class="w-full h-full">
    <slot />
  </div>
</template>

<script setup>


</script>

<style>

  
  </style>
