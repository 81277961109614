<template>
  <div class="w-full">
    <section class="w-full min-h-screen flex justify-center items-center flex-col relative sm:mt-0 -mt-20">
     <!-- <div class="w-full grid grid-cols-3 sm:p-4 px-2 justify-center items-center text-center absolute top-4">
        <div class="flex justify-center items-center sm:space-x-4 sm:flex-row flex-col">
          <h2 class="font-brand font-bold text-turquoise-500 sm:text-3xl text-xl">$7M</h2>
          <p class="sm:text-base text-sm text-white">TVL</p>
        </div>

        <div class="flex justify-center items-center sm:space-x-4 sm:flex-row flex-col">
          <h2 class="font-brand font-bold text-turquoise-500 sm:text-3xl text-xl">197X</h2>
          <p class="sm:text-base text-sm text-white">Average ATH</p>
        </div>

        <div class="flex justify-center items-center sm:space-x-4 sm:flex-row flex-col">
          <h2 class="font-brand font-bold text-turquoise-500 sm:text-3xl text-xl">1337</h2>
          <p class="sm:text-base text-sm text-white">Participants</p>
        </div>
      </div>-->

      <div class="w-full flex flex-col justify-center items-center -mt-20 z-50">
        <div class="w-full flex flex-col justify-center items-center mb-4 text-center">
          <NuxtImg class="sm:h-3 h-3 mb-8" src="/oxbull-logo.webp" height="16" alt="Oxbull.Tech Logo" />



          <!-- <h1 class="text-white sm:text-[4vw] text-4xl font-brand font-bold mt-16 leading-snug sm:leading-normal mb-8">Bullish Action,<span class="sm:hidden"><br></span>Bullish Result</h1> -->

          <div class="w-max flex justify-center items-center overflow-hidden relative">
        <div class="w-[300px] h-[300px] flex justify-center items-center relative">
          <GoldBull class="z-20"></GoldBull>
          
          <div class="w-[300px] h-[300px] absolute animate-spin-slow flex justify-center items-center" >
            <svg width="549" height="550" viewBox="0 0 549 550" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M238.666 488.305L228.319 528.4L205.204 522.435C199.834 521.049 195.999 519.09 193.7 516.558C191.349 514.056 190.632 511.029 191.549 507.476C192.135 505.204 193.157 503.551 194.613 502.517C196.059 501.524 197.895 500.984 200.121 500.897C202.305 500.8 204.802 501.026 207.613 501.575L207.253 501.945C205.239 501.381 203.376 500.746 201.664 500.04C199.91 499.323 198.437 498.458 197.244 497.445C196.051 496.432 195.217 495.226 194.741 493.825C194.255 492.466 194.257 490.836 194.747 488.935C195.387 486.457 196.581 484.561 198.329 483.25C200.067 481.98 202.22 481.236 204.788 481.017C207.356 480.799 210.2 481.048 213.32 481.765L238.666 488.305ZM228.665 492.995L212.428 488.805C209.826 488.134 207.715 488.03 206.097 488.493C204.469 488.999 203.415 490.181 202.935 492.04C202.583 493.403 202.741 494.501 203.407 495.334C204.073 496.167 205.072 496.821 206.403 497.297C207.724 497.814 209.169 498.275 210.739 498.68L226.17 502.662L228.665 492.995ZM224.459 509.293L209.896 505.535C207.293 504.863 205.193 504.718 203.597 505.099C201.99 505.522 200.946 506.663 200.466 508.522C199.987 510.381 200.353 511.863 201.566 512.97C202.768 514.117 204.526 514.989 206.839 515.586L221.836 519.456L224.459 509.293Z" fill="white"/>
<path d="M155.606 456.086L162.937 460.022L147.891 488.047L140.561 484.111L155.606 456.086ZM155.307 473.287L156.28 471.339C156.637 470.853 157.239 470.184 158.085 469.33C158.91 468.514 159.945 467.714 161.19 466.929C162.397 466.124 163.771 465.458 165.314 464.93C166.836 464.44 168.493 464.24 170.285 464.33C172.056 464.458 173.92 465.047 175.874 466.096C177.416 466.924 178.837 467.929 180.139 469.112C181.403 470.275 182.405 471.612 183.143 473.122C183.862 474.67 184.175 476.388 184.082 478.275C183.989 480.162 183.327 482.252 182.096 484.545L172.983 501.518L165.653 497.582L174.099 481.85C175.068 480.046 175.553 478.466 175.554 477.111C175.498 475.773 175.016 474.595 174.111 473.576C173.205 472.557 171.981 471.633 170.44 470.806C168.41 469.716 166.43 469.21 164.5 469.287C162.549 469.403 160.78 469.857 159.192 470.651C157.566 471.424 156.271 472.303 155.307 473.287Z" fill="white"/>
<path d="M117.964 483.286L111.389 478.187L138.728 442.939L145.302 448.038L117.964 483.286Z" fill="white"/>
<path d="M97.1083 465.906L91.1058 460.145L121.995 427.962L127.998 433.724L97.1083 465.906Z" fill="white"/>
<path d="M71.5774 440.373L77.1544 435.669L83.3852 443.056L77.8082 447.76L71.5774 440.373ZM88.0605 438.275L82.6962 431.915L107.01 411.408L112.374 417.767L88.0605 438.275Z" fill="white"/>
<path d="M90.1555 407.633L86.128 401.094C87.1171 399.933 87.6309 398.489 87.6694 396.762C87.6492 395.021 87.0127 393.133 85.7597 391.098C84.9095 389.718 84.1223 388.724 83.3981 388.118C82.6739 387.512 81.9862 387.209 81.3349 387.209C80.6473 387.232 79.9947 387.433 79.3771 387.813C78.6142 388.283 78.1784 388.877 78.0696 389.596C77.9245 390.337 78.0269 391.276 78.3767 392.413C78.7265 393.551 79.2623 394.95 79.984 396.61C80.6694 398.292 81.2668 399.954 81.7761 401.594C82.2854 403.235 82.5851 404.779 82.675 406.227C82.7287 407.697 82.5027 409.039 81.9972 410.252C81.433 411.452 80.4789 412.465 79.1347 413.293C77.8268 414.099 76.4741 414.506 75.0765 414.515C73.6565 414.487 72.2546 414.123 70.8707 413.422C69.4645 412.684 68.1099 411.665 66.8068 410.363C65.4814 409.024 64.2706 407.465 63.1742 405.685C61.5185 402.996 60.5421 400.516 60.245 398.244C59.9116 395.994 60.1848 393.996 61.0646 392.252C61.8858 390.493 63.2046 389.055 65.0211 387.936L68.8807 394.203C67.7155 395.321 67.1107 396.495 67.0665 397.725C66.9858 398.978 67.5049 400.512 68.6236 402.328C69.72 404.109 70.8203 405.285 71.9246 405.858C73.0289 406.43 74.0533 406.426 74.9979 405.844C75.7608 405.374 76.1925 404.732 76.2928 403.919C76.3344 403.092 76.1468 402.055 75.7298 400.808C75.2905 399.525 74.7016 397.959 73.963 396.109C73.381 394.513 72.8801 392.967 72.4603 391.472C72.0181 389.94 71.7744 388.487 71.7292 387.112C71.6477 385.759 71.9002 384.501 72.4868 383.338C73.0146 382.161 73.987 381.136 75.4038 380.264C77.1477 379.19 78.9322 378.792 80.7576 379.071C82.5606 379.314 84.3596 380.16 86.1546 381.61C87.9133 383.082 89.587 385.108 91.1755 387.688C92.5851 389.976 93.5684 392.102 94.1254 394.064C94.6236 396.012 94.8269 397.766 94.7352 399.325C94.6072 400.908 94.2974 402.276 93.8059 403.431C93.2556 404.571 92.648 405.497 91.983 406.207C91.318 406.917 90.7088 407.392 90.1555 407.633Z" fill="white"/>
<path d="M67.9294 338.286L51.4668 344.138C49.8186 344.724 48.5517 345.492 47.6664 346.441C46.781 347.389 46.2785 348.587 46.1587 350.033C46.0247 351.439 46.3221 353.167 47.051 355.218C47.7514 357.188 48.7693 358.841 50.1048 360.177C51.4001 361.528 52.8295 362.492 54.3928 363.068C55.916 363.659 57.3624 363.778 58.7319 363.427L55.4367 364.667C54.0386 364.937 52.5476 364.947 50.9636 364.695C49.3797 364.443 47.8307 363.907 46.3164 363.086C44.8022 362.266 43.4234 361.126 42.1798 359.667C40.8818 358.181 39.8541 356.373 39.0966 354.242C38.4391 352.393 38.0729 350.598 37.9979 348.859C37.8827 347.134 38.1003 345.517 38.6507 344.008C39.2011 342.499 40.0855 341.166 41.3041 340.008C42.4825 338.865 44.0365 337.95 45.9662 337.264L65.1424 330.446L67.9294 338.286ZM77.4696 365.12L35.4388 380.063L32.6518 372.224L74.6826 357.281L77.4696 365.12Z" fill="white"/>
<path d="M50.0972 291.352L42.9933 291.374L42.9093 263.982L50.0133 263.96L50.0972 291.352ZM59.1528 301.629L17.6853 282.3L17.6571 273.084L59.0047 253.309L59.0331 262.589L21.7735 279.727L21.7609 275.631L59.1245 292.413L59.1528 301.629Z" fill="white"/>
<path d="M57.4607 204.416C60.1063 205.275 62.2184 206.707 63.7968 208.712C65.3853 210.677 66.3772 213.111 66.7726 216.016C67.1266 218.911 66.8626 222.183 65.9807 225.832C65.0587 229.648 63.6306 232.924 61.6962 235.661C59.7305 238.346 57.3552 240.274 54.5703 241.445C51.754 242.564 48.5832 242.698 45.058 241.846C41.5328 240.994 38.7729 239.427 36.7784 237.146C34.7939 234.823 33.5403 232.018 33.0177 228.731C32.5051 225.403 32.7098 221.831 33.6318 218.015C34.5137 214.366 35.7887 211.36 37.4569 208.998C39.0836 206.625 41.0313 204.923 43.2998 203.891C45.5784 202.818 48.0956 202.482 50.8515 202.885L49.0626 210.288C46.8186 210.492 44.8854 211.407 43.2632 213.034C41.641 214.662 40.5041 216.823 39.8527 219.519C39.3316 221.675 39.2288 223.735 39.5444 225.699C39.87 227.621 40.649 229.302 41.8815 230.741C43.0825 232.129 44.8028 233.093 47.0423 233.634C49.2404 234.166 51.2112 234.093 52.9548 233.417C54.6569 232.731 56.0966 231.586 57.2741 229.984C58.4101 228.371 59.2386 226.486 59.7598 224.329C60.4613 221.426 60.4519 218.922 59.7316 216.816C59.0213 214.669 57.6681 213.004 55.6718 211.819L57.4607 204.416Z" fill="white"/>
<path d="M43.9482 186.791L54.1265 164.282L60.0163 166.945L49.8381 189.455L43.9482 186.791ZM39.3925 175.741L42.8204 168.16L79.7341 184.851L76.3062 192.432L39.3925 175.741Z" fill="white"/>
<path d="M54.2217 136.72L60.4312 140.55L55.3574 148.775L49.1478 144.944L54.2217 136.72ZM61.3582 151.725L65.7264 144.644L92.7978 161.344L88.4296 168.425L61.3582 151.725Z" fill="white"/>
<path d="M112.656 136.276C110.005 139.286 107.184 141.52 104.193 142.98C101.231 144.407 98.2728 144.958 95.3197 144.631C92.3346 144.277 89.4972 142.915 86.8074 140.546C84.1177 138.178 82.4082 135.535 81.6789 132.619C80.9176 129.675 81.0577 126.643 82.0991 123.523C83.1688 120.372 85.0289 117.291 87.6795 114.281C90.3301 111.271 93.1226 109.069 96.0571 107.673C99.0198 106.246 102.01 105.723 105.027 106.106C108.012 106.461 110.849 107.822 113.539 110.191C116.229 112.56 117.938 115.202 118.668 118.118C119.365 121.006 119.193 124.01 118.151 127.13C117.138 130.217 115.306 133.266 112.656 136.276ZM107.852 132.046C109.319 130.381 110.342 128.638 110.922 126.818C111.497 124.937 111.563 123.119 111.119 121.364C110.643 119.58 109.589 117.969 107.956 116.531C106.323 115.093 104.592 114.251 102.762 114.004C100.901 113.729 99.0876 113.98 97.3231 114.757C95.5585 115.534 93.929 116.772 92.4346 118.469C90.9683 120.134 89.9471 121.907 89.3712 123.787C88.7953 125.668 88.7312 127.516 89.179 129.332C89.655 131.115 90.7256 132.74 92.3906 134.206C94.0237 135.645 95.755 136.487 97.5844 136.734C99.3819 136.952 101.177 136.657 102.97 135.848C104.73 135.01 106.358 133.743 107.852 132.046Z" fill="white"/>
<path d="M115.277 87.4028L122.06 82.5836L140.484 108.512L133.701 113.332L115.277 87.4028ZM133.703 73.4465C135.303 72.3096 136.93 71.468 138.583 70.9215C140.236 70.3751 141.875 70.1783 143.502 70.3311C145.128 70.4839 146.702 71.0408 148.222 72.0018C149.718 72.9281 151.12 74.3129 152.43 76.1562L163.7 92.0162L156.917 96.8353L146.501 82.1753C144.944 79.9842 143.268 78.7673 141.473 78.5248C139.688 78.2228 137.596 78.9244 135.196 80.6296C133.387 81.9147 132 83.4241 131.033 85.1577C130.067 86.8913 129.514 88.6184 129.376 90.3392C129.213 92.0251 129.47 93.4914 130.147 94.738L128.204 92.1144C127.513 90.7735 127.072 89.3068 126.883 87.7142C126.693 86.1216 126.787 84.485 127.163 82.8045C127.549 81.0646 128.275 79.3974 129.34 77.8029C130.406 76.2084 131.86 74.7563 133.703 73.4465Z" fill="white"/>
<path d="M231.746 61.0806L226.255 57.9159L232.679 46.7784L227.915 48.0458L195.48 55.7788L194.025 49.6778L226.46 41.9448L231.221 40.9413L220.54 33.9477L223.999 28.5835L242.876 41.255L231.746 61.0806Z" fill="white"/>
<path d="M295.268 59.1404L303.082 18.4764L326.525 22.9812C331.972 24.0279 335.922 25.7427 338.375 28.1258C340.878 30.475 341.783 33.4513 341.091 37.0547C340.648 39.3592 339.732 41.0732 338.343 42.1966C336.962 43.2781 335.164 43.9318 332.948 44.1578C330.774 44.3917 328.268 44.3228 325.428 43.951L325.764 43.5593C327.809 43.9957 329.708 44.5127 331.462 45.1104C333.257 45.716 334.782 46.4869 336.035 47.423C337.289 48.3591 338.198 49.5112 338.76 50.8793C339.331 52.2055 339.431 53.8323 339.061 55.7597C338.577 58.2737 337.505 60.2398 335.842 61.6582C334.187 63.0347 332.085 63.9124 329.535 64.2915C326.986 64.6706 324.132 64.6001 320.974 64.08L295.268 59.1404ZM304.955 53.8332L321.422 56.9974C324.062 57.5047 326.174 57.4761 327.76 56.9119C329.354 56.3057 330.332 55.0599 330.694 53.1744C330.96 51.7917 330.734 50.7056 330.017 49.9161C329.3 49.1265 328.262 48.5361 326.903 48.1447C325.553 47.7114 324.081 47.3417 322.489 47.0358L306.839 44.0286L304.955 53.8332ZM308.132 37.3036L322.901 40.1417C325.541 40.649 327.646 40.6624 329.215 40.1819C330.793 39.6596 331.763 38.4556 332.125 36.5701C332.487 34.6846 332.029 33.2279 330.749 32.2C329.477 31.1301 327.668 30.3697 325.322 29.9189L310.112 26.9962L308.132 37.3036Z" fill="white"/>
<path d="M380.183 86.0912L372.62 82.6228L385.88 53.7105L393.443 57.179L380.183 86.0912ZM379.404 68.9061L378.555 70.9107C378.228 71.418 377.67 72.1241 376.879 73.029C376.107 73.895 375.124 74.7586 373.931 75.6197C372.777 76.4986 371.447 77.2497 369.94 77.8732C368.452 78.4578 366.81 78.7613 365.016 78.7835C363.24 78.7669 361.344 78.2961 359.327 77.3712C357.737 76.6419 356.255 75.7276 354.882 74.6283C353.547 73.5468 352.464 72.2755 351.632 70.8143C350.818 69.3144 350.398 67.6198 350.373 65.7305C350.347 63.8413 350.877 61.7138 351.962 59.3481L359.993 41.8378L367.556 45.3063L360.112 61.5367C359.258 63.3982 358.873 65.0054 358.957 66.3581C359.097 67.6898 359.651 68.8357 360.619 69.796C361.587 70.7562 362.866 71.6009 364.456 72.3302C366.55 73.2907 368.558 73.6717 370.479 73.4734C372.419 73.2363 374.156 72.6719 375.692 71.7802C377.266 70.9063 378.504 69.9482 379.404 68.9061Z" fill="white"/>
<path d="M416.047 56.5871L422.928 61.2642L397.852 98.1567L390.971 93.4796L416.047 56.5871Z" fill="white"/>
<path d="M437.951 72.6269L444.302 78.0008L415.49 112.056L409.138 106.682L437.951 72.6269Z" fill="white"/>
<path d="M465.031 96.5093L459.76 101.554L453.078 94.5713L458.35 89.5271L465.031 96.5093ZM448.712 99.6355L454.464 105.647L431.483 127.638L425.731 121.627L448.712 99.6355Z" fill="white"/>
<path d="M448.541 130.35L452.97 136.624C452.056 137.844 451.634 139.318 451.703 141.044C451.833 142.781 452.586 144.625 453.964 146.577C454.899 147.901 455.747 148.844 456.508 149.403C457.269 149.963 457.974 150.222 458.624 150.181C459.309 150.116 459.948 149.874 460.54 149.455C461.272 148.939 461.67 148.318 461.733 147.595C461.832 146.846 461.671 145.915 461.25 144.802C460.83 143.689 460.208 142.326 459.383 140.714C458.594 139.078 457.893 137.457 457.282 135.852C456.671 134.247 456.275 132.724 456.095 131.285C455.949 129.821 456.091 128.467 456.519 127.225C457.007 125.993 457.896 124.921 459.186 124.011C460.44 123.125 461.765 122.634 463.159 122.537C464.578 122.476 466 122.751 467.425 123.364C468.875 124.012 470.291 124.945 471.673 126.163C473.079 127.416 474.386 128.896 475.591 130.604C477.412 133.183 478.542 135.598 478.981 137.847C479.455 140.072 479.307 142.082 478.538 143.878C477.829 145.685 476.603 147.203 474.86 148.434L470.616 142.421C471.708 141.232 472.238 140.022 472.205 138.792C472.207 137.537 471.593 136.038 470.363 134.295C469.157 132.587 467.985 131.482 466.847 130.98C465.709 130.477 464.687 130.546 463.781 131.186C463.049 131.703 462.658 132.37 462.609 133.188C462.619 134.017 462.872 135.04 463.366 136.258C463.885 137.51 464.571 139.037 465.424 140.837C466.105 142.393 466.701 143.905 467.214 145.371C467.751 146.872 468.086 148.307 468.217 149.676C468.383 151.022 468.21 152.293 467.697 153.49C467.244 154.698 466.338 155.782 464.979 156.741C463.306 157.923 461.549 158.431 459.71 158.267C457.895 158.138 456.047 157.406 454.165 156.071C452.317 154.712 450.52 152.795 448.773 150.32C447.222 148.124 446.108 146.065 445.429 144.141C444.81 142.228 444.497 140.491 444.491 138.928C444.519 137.341 444.743 135.956 445.161 134.773C445.639 133.6 446.187 132.639 446.806 131.888C447.426 131.138 448.004 130.625 448.541 130.35Z" fill="white"/>
<path d="M475.069 198.169L491.132 191.296C492.74 190.608 493.957 189.763 494.781 188.761C495.605 187.758 496.031 186.532 496.06 185.081C496.106 183.669 495.701 181.963 494.845 179.962C494.023 178.04 492.903 176.454 491.486 175.204C490.109 173.937 488.622 173.065 487.026 172.587C485.468 172.093 484.017 172.064 482.673 172.501L485.884 171.057C487.262 170.699 488.75 170.597 490.346 170.749C491.943 170.901 493.522 171.339 495.085 172.063C496.648 172.786 498.095 173.838 499.428 175.217C500.816 176.618 501.955 178.359 502.845 180.438C503.617 182.242 504.095 184.01 504.279 185.741C504.502 187.456 504.386 189.083 503.931 190.623C503.476 192.164 502.677 193.55 501.534 194.782C500.429 195.997 498.936 197.007 497.053 197.813L478.341 205.818L475.069 198.169ZM463.866 171.985L504.878 154.438L508.15 162.087L467.138 179.634L463.866 171.985Z" fill="white"/>
<path d="M486.472 238.005L527.802 235.473L529.52 263.516C529.716 266.711 529.439 269.506 528.688 271.903C527.982 274.34 526.733 276.276 524.94 277.711C523.19 279.144 520.866 279.948 517.971 280.126C516.012 280.246 514.33 280.007 512.925 279.409C511.521 278.811 510.355 277.921 509.427 276.738C508.503 275.598 507.758 274.254 507.193 272.707C506.67 271.157 506.29 269.492 506.053 267.711L506.509 266.786C506.649 269.769 506.544 272.234 506.193 274.179C505.884 276.121 505.163 277.598 504.028 278.608C502.938 279.658 501.243 280.253 498.943 280.394L489.106 280.997L488.585 272.501L497.848 271.933C499.424 271.837 500.62 271.485 501.438 270.88C502.299 270.271 502.855 269.233 503.107 267.764C503.361 266.337 503.406 264.283 503.242 261.6L502.26 245.566L486.992 246.501L486.472 238.005ZM508.903 245.159L510.101 264.706C510.247 267.091 510.933 268.866 512.158 270.03C513.386 271.238 514.98 271.781 516.939 271.661C518.813 271.546 520.2 270.82 521.102 269.482C522.005 268.187 522.384 266.348 522.238 263.963L521.041 244.415L508.903 245.159Z" fill="white"/>
<path d="M493.01 324.138L491.538 332.067C489.461 331.334 487.719 330.078 486.314 328.298C484.901 326.56 483.941 324.364 483.436 321.71C482.931 319.056 483.009 315.946 483.671 312.38C484.411 308.395 485.694 304.988 487.521 302.159C489.39 299.338 491.701 297.294 494.453 296.026C497.205 294.757 500.302 294.443 503.741 295.081C507.181 295.72 509.962 297.104 512.084 299.233C514.247 301.371 515.703 304.05 516.45 307.269C517.19 310.531 517.186 314.176 516.438 318.203C515.675 322.314 514.39 325.612 512.584 328.098C510.777 330.583 508.432 332.231 505.547 333.04C502.697 333.9 499.257 333.847 495.227 332.882L500.764 303.056C499.131 302.969 497.575 303.288 496.095 304.011C494.608 304.777 493.331 305.928 492.264 307.466C491.19 309.046 490.434 311.011 489.998 313.36C489.516 315.961 489.555 318.203 490.117 320.086C490.712 322.02 491.677 323.37 493.01 324.138ZM510.279 316.669C510.84 313.649 510.624 311.07 509.632 308.933C508.682 306.804 507.293 305.287 505.464 304.384L501.516 325.652C503.653 325.832 505.497 325.133 507.048 323.555C508.633 322.027 509.711 319.731 510.279 316.669Z" fill="white"/>
<path d="M487.866 343.395L484.934 350.494C483.415 350.374 481.944 350.805 480.522 351.788C479.124 352.826 477.969 354.449 477.057 356.657C476.438 358.156 476.095 359.376 476.029 360.318C475.963 361.261 476.122 361.995 476.504 362.522C476.926 363.066 477.472 363.476 478.142 363.753C478.97 364.095 479.707 364.099 480.353 363.766C481.038 363.449 481.738 362.815 482.454 361.864C483.17 360.913 483.988 359.659 484.909 358.1C485.869 356.558 486.864 355.099 487.893 353.724C488.923 352.349 489.998 351.2 491.117 350.278C492.276 349.372 493.495 348.767 494.774 348.465C496.077 348.218 497.457 348.396 498.916 348.998C500.336 349.585 501.46 350.441 502.287 351.567C503.098 352.733 503.625 354.082 503.87 355.614C504.098 357.186 504.067 358.881 503.777 360.7C503.471 362.559 502.919 364.454 502.121 366.386C500.916 369.305 499.48 371.551 497.815 373.125C496.188 374.715 494.411 375.666 492.482 375.977C490.576 376.344 488.637 376.12 486.666 375.306L489.475 368.503C491.065 368.79 492.37 368.591 493.392 367.905C494.453 367.236 495.391 365.915 496.206 363.943C497.004 362.011 497.311 360.43 497.126 359.199C496.942 357.969 496.337 357.142 495.312 356.719C494.484 356.377 493.711 356.404 492.993 356.8C492.299 357.252 491.569 358.012 490.805 359.081C490.024 360.19 489.101 361.586 488.036 363.27C487.086 364.677 486.128 365.99 485.163 367.207C484.183 368.464 483.149 369.514 482.062 370.358C481.014 371.217 479.848 371.751 478.562 371.959C477.299 372.222 475.898 372.036 474.36 371.401C472.468 370.619 471.098 369.407 470.253 367.765C469.392 366.163 469.021 364.21 469.142 361.905C469.302 359.617 469.961 357.073 471.117 354.273C472.143 351.789 473.287 349.746 474.549 348.143C475.834 346.597 477.135 345.403 478.452 344.562C479.808 343.737 481.098 343.185 482.321 342.906C483.568 342.682 484.674 342.631 485.639 342.753C486.604 342.874 487.347 343.089 487.866 343.395Z" fill="white"/>
<path d="M440.369 408.467L445.081 401.61L471.296 419.626L466.583 426.483L440.369 408.467ZM457.434 410.643L455.604 409.464C455.16 409.055 454.56 408.384 453.804 407.45C453.083 406.54 452.401 405.424 451.757 404.101C451.088 402.814 450.576 401.374 450.22 399.784C449.899 398.217 449.881 396.548 450.166 394.777C450.487 393.03 451.275 391.242 452.532 389.413C453.523 387.972 454.677 386.668 455.996 385.504C457.29 384.374 458.728 383.524 460.31 382.955C461.927 382.41 463.669 382.286 465.534 382.585C467.4 382.883 469.405 383.77 471.55 385.244L487.426 396.155L482.714 403.012L467.998 392.898C466.31 391.738 464.793 391.084 463.446 390.934C462.11 390.845 460.886 391.194 459.774 391.983C458.662 392.772 457.611 393.888 456.62 395.329C455.315 397.228 454.596 399.141 454.462 401.068C454.364 403.02 454.622 404.828 455.238 406.494C455.829 408.194 456.561 409.578 457.434 410.643Z" fill="white"/>
<path d="M463.295 448.855L457.509 454.833L425.456 423.809L431.242 417.831L463.295 448.855Z" fill="white"/>
<path d="M437.409 456.583L418.101 471.994L414.068 466.942L433.376 451.531L437.409 456.583ZM436.436 468.497L429.933 473.687L404.661 442.024L411.164 436.834L436.436 468.497Z" fill="white"/>
<path d="M311.924 488.203L317.776 490.638L312.832 502.507L317.394 500.639L348.571 488.814L350.795 494.678L319.618 506.504L315.025 508.109L326.514 513.676L323.772 519.439L303.427 509.291L311.924 488.203Z" fill="white"/>
</svg>

          </div>
          <!-- <img class="w-[300px] h-[300px] absolute animate-spin-slow" src="/bull_text_circle.svg"> -->
        </div>
      </div>

          
          <h2 class="text-white sm:max-w-full max-w-sm text-xl mt-4 flex justify-center items-center flex-col group">

            <span class="text-sm absolute translate-y-0 group-hover:translate-y-[-200%] duration-300 opacity-0 group-hover:opacity-100">View in Cryptorank</span>

            <a id="hero_section_launch_cryptorank" class="p-2 px-3 hover:bg-white/10 rounded-md duration-300 flex justify-center items-center text-white" href="https://cryptorank.io/fundraising-platforms/oxbull" target="_blank">Top Launchpad in both Current ROI and All-Time High ROI <Icon name="gridicons:external" class="text-turquoise-500 w-5 h-5 ml-2"></Icon></a>

            
          </h2>
        </div>

        <div class="flex justify-center items-center space-x-4">
          <!-- <a id="hero_section_buy_oxb" href="https://pancakeswap.finance/swap?outputCurrency=0x7536c00711E41df6aEBCCa650791107645b6bc52" target="_blank" class="w-[160px] btn bg-white text-black text-lg font-semibold hover:text-white hover:bg-white/20">Buy $OXI</a> -->
          <a id="hero_section_buy_oxb" href="https://app.uniswap.org/swap?outputCurrency=0x07fb80f7A8460DE3065d74B2663ba0D740fB3cA5&chain=base" target="_blank" class="w-[160px] btn bg-white text-black text-lg font-semibold hover:text-white hover:bg-white/20">Buy $OXI</a>
          <a id="hero_section_launch_app" href="/app" target="_blank" class="w-[160px] border-transparent btn bg-white/10 text-turquoise-500 text-lg font-semibold hover:text-black hover:bg-turquoise-500">Launch DAPP</a>
        </div>
      </div>


    <div
      class="absolute bottom-0 h-96 w-full overflow-hidden [mask-image:radial-gradient(50%_50%,white,transparent)] before:absolute before:inset-0 before:bg-[radial-gradient(circle_at_bottom_center,#27E9AF,transparent_70%)] before:opacity-40 after:absolute after:-left-1/2 after:top-1/2 after:aspect-[1/0.7] after:w-[200%] after:rounded-[100%] after:border-t after:border-[#27E9AF66] after:bg-zinc-900">
      
    </div>


    <div v-if="nuxtReady" class="w-full absolute bottom-8 pb-4">
      <ClientOnly>
      <vue3-seamless-scroll :step="0.25" direction="left" :single-line="true" :list="projects" class="w-full overflow-hidden">
        <div v-for="project in projects" class="px-4 group">
          <div class=" rounded-md px-4 p-2 flex justify-start items-center pr-4 flex-shrink-0  group-hover:border-white/20 border border-transparent duration-300 cursor-pointer
          " >
            
            <div class="w-8 h-8 mr-4 overflow-hidden opacity-50 group-hover:opacity-100">
              <img class="w-full h-full object-contain" :src="project.src" alt="">
            </div>
    
            <p class="mr-8 text-zinc-300 font-brand font-bold" >{{ project.title }}</p>
    
            <p class="text-lg text-zinc-400">{{ project.roi }}</p>
          </div>

        </div>
    </vue3-seamless-scroll>
    </ClientOnly>
    </div>


    <!-- <div class="w-full overflow-hidden absolute bottom-0 flex justify-start items-center animate-marquee [--duration:30s]">

      <div v-for="project in projects" class="w-[500px] flex-shrink-0 p-4 border">
        <img class="w-16 h-16 object-contain" :src="project.src" alt="">
      </div>


      <div v-for="project in projects" class="w-[500px] flex-shrink-0 p-4 border">
        <img class="w-16 h-16 object-contain" :src="project.src" alt="">
      </div>
    </div>
     -->


      <!--<div class="absolute bottom-10 flex justify-center flex-col items-center w-full max-w-[100vw] overflow-hidden py-12">
        <div class="rotate-[3deg] relative z-10">
          <ScrollerBar theme="black" direction="right" class="" text="Launchpad for everyone" />
        </div>
        <div class="rotate-[-3deg] absolute">
          <ScrollerBar theme="black" direction="left" class="opacity-20" text="Launchpad for everyone" />
        </div>
      </div>-->


    </section>
  </div>
</template>

<script  setup>

import { Vue3SeamlessScroll } from "vue3-seamless-scroll";

const nuxtReady = ref(false)

onNuxtReady(async () => {
  nuxtReady.value = true
})

const projects = [
  {
    "title": "Catecoin",
    "src": "/images/catecoin-logo.png",
    "roi": "3563.64x"
  },
  {
    "title": "Altura",
    "src": "/images/altura-logo.png",
    "roi": "811.75x"
  },
  {
    "title": "Bloktopia",
    "src": "/images/bloktopia-logo.png",
    "roi": "713.2x"
  },
  {
    "title": "CryptoBlades",
    "src": "https://oxbull.twic.pics/cryptoblades_logo.png",
    "roi": "306.8x"
  },
  {
    "title": "Catcoin",
    "src": "https://oxbull.twic.pics/catcoin_logo.png",
    "roi": "252.24x"
  },
  {
    "title": "Sidus Heroes",
    "src": "/images/sidus-logo.png",
    "roi": "161.08x"
  },
  {
    "title": "Wanaka",
    "src": "https://oxbull.twic.pics/wanaka_logo.png",
    "roi": "153.75x"
  },
  {
    "title": "Octavia",
    "src": "https://oxbull.twic.pics/octavia_logo.png",
    "roi": "13.14x"
  },
  {
    "title": "Vision Game",
    "src": "https://oxbull.twic.pics/visiongame_logo.png",
    "roi": "30.03x"
  },
  {
    "title": "Farcana",
    "src": "/images/farcana-logo.webp",
    "roi": "26.89x"
  }
];


</script>
