import routerOptions0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.0_rollup@2.79.2_sass@1.83.0_tsx@4.19.2_typescript@5.7.2_vite@6.0.7/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/opt/buildhome/repo/app/router.options.js";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}